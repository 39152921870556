import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Form, Input, Row, Col, Button, message } from 'antd';
import { useMutation } from '@apollo/client';

import WhatsNew from 'components/WhatsNew/WhatsNew';
import PasswordEyeIcon from 'components/Icons/PasswordEyeIcon';
import PasswordEyeShutIcon from 'components/Icons/PasswordEyeShutIcon';
import Path from 'routes/path';
import { USER_RESET_PASSWORD } from 'graphql/mutations/userMutations';

import utils from 'utils/utils';
import images from 'constants/images';

import 'pages/ForgotPassword/ForgotPassword.scss';

const ResetPassword = ({ match: { params }, history }) => {
  const pageName = history.location.pathname.indexOf('update') ? 'Set New' : 'Reset';
  const [newPassword, setNewPassword] = useState({
    value: '',
  });
  const [confirmPassword, setConfirmPassword] = useState({
    value: '',
  });
  const [form] = Form.useForm();

  const [saveNewPassword] = useMutation(USER_RESET_PASSWORD, {
    async onCompleted(data) {
      if (data.resetPassword) {
        history.push('/login');
      } else {
        message.error(`${pageName} Password Link Expired`);
      }
    },
    onError() {
      message.error(`${pageName} Password Link Expired`);
    },
  });

  useEffect(() => {
    document.title = `Prospero - ${pageName} Password`;
  });

  const onFinish = async (values) => {
    if (
      values.newPassword.validateStatus === 'error' ||
      values.confirmPassword.validateStatus === 'error'
    ) {
      return;
    }

    if (values.newPassword !== values.confirmPassword) {
      setConfirmPassword({
        validateStatus: 'error',
        errorMsg: `Confirm password doesn't match new password`,
      });
    } else {
      saveNewPassword({
        variables: {
          token: params.token,
          newPassword: values.newPassword,
        },
      });
      form.resetFields();
    }
  };

  const validatePassword = (value) => {
    const errorResponse = utils.simplePasswordPolicy(value);
    if (errorResponse === null) {
      return {
        validateStatus: 'success',
        errorMsg: null,
      };
    } else {
      return {
        validateStatus: 'error',
        errorMsg: errorResponse,
      };
    }
  };

  const onPasswordChange = (value) => {
    setNewPassword({
      ...validatePassword(value),
      value,
    });
  };

  const onConfirmPasswordChange = (value) => {
    setConfirmPassword({
      ...validatePassword(value),
      value,
    });
  };

  return (
    <Row className="forgot-container">
      <Col className="forgot-left">
        <Row className="header-forgot">
          <Col className="logo">
            <NavLink to={Path.HOME}>
              <img src={images.LOGO} alt="prospero-logo" height="20" />
            </NavLink>
          </Col>
        </Row>
        <div className="content-wrapper">
          <div className="heading-wrap">
            <h1 className="heading">Set New Password</h1>
            <p className="sub-heading-text">
              Please create a strong and unique password to
              <br />
              secure your account.
            </p>
            <div className="color-divider" />
          </div>
          <div className="error-message"></div>
          <Form layout="vertical email-input" form={form} onFinish={onFinish}>
            <Form.Item
              label="New Password"
              name="newPassword"
              className="password-reset-input"
              rules={[{ required: true, message: 'Password Required!' }]}
              validateStatus={newPassword.validateStatus}
              help={newPassword.errorMsg}>
              <Input.Password
                placeholder="********"
                value={newPassword.value}
                onChange={(e) => onPasswordChange(e.target.value)}
                iconRender={(visible) => (visible ? <PasswordEyeShutIcon /> : <PasswordEyeIcon />)}
                min={8}
              />
            </Form.Item>
            <Form.Item
              label="Confirm Password"
              name="confirmPassword"
              rules={[{ required: true, message: 'Confirm Password Required!' }]}
              validateStatus={confirmPassword.validateStatus}
              help={confirmPassword.errorMsg}>
              <Input.Password
                placeholder="********"
                value={confirmPassword.value}
                onChange={(e) => onConfirmPasswordChange(e.target.value)}
                iconRender={(visible) => (visible ? <PasswordEyeShutIcon /> : <PasswordEyeIcon />)}
                min={8}
              />
            </Form.Item>
            <Button type="primary" htmlType="submit" size="large" block className="forgot-btn">
              <span className="spacer" />
              <span className="text-content">{pageName.toUpperCase()} PASSWORD</span>
            </Button>
          </Form>
        </div>
      </Col>
      <Col className="forgot-right">
        <WhatsNew />
      </Col>
    </Row>
  );
};

ResetPassword.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.instanceOf(Object).isRequired,
  }).isRequired,
};

export default ResetPassword;
