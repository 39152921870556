import React from 'react';
import { Row, Col, Divider, Radio, Tooltip } from 'antd';
import PropTypes from 'prop-types';

import Currency from './Currency';
import Button from 'components/Button';
import SlidersIcon from 'components/Icons/SliderIcon';
import TickIcon from 'components/Icons/TickIcon';
import OpenLockIcon from 'components/Icons/OpenLockIcon';
import CloseLockIcon from 'components/Icons/CloseLockIcon';
import OverflowDropDownList from 'components/OverflowDropDownList/OverflowDropDownList';

const TotalOptions = ({
  configText,
  prop,
  save,
  curr,
  quantity,
  tax,
  discount,
  toggleDiscount,
  toggleTax,
  toggleQuantity,
  toggleSelectable,
  selectable,
  defaultStab,
  dispatch,
  isWizard,
  clickDiscountBtn,
  clickTaxBtn,
  handleState,
  openDrawer,
  showPane,
  setDiscountBtn,
  disctBtn,
  setTaxBtn,
  taxBtn,
}) => {
  // varius breakpoints to show no. of buttons in the OverflowDropDownList
  const wizardBreakPoints = [2200, 2100, 1880, 1680, 1550, 1300, 768, 576, 480];

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="sortlist-options-container">
      <Col span={12}>
        <Currency
          configText={configText}
          prop={prop}
          curr={curr}
          onChange={(currValue) => {
            save({ curr: currValue }, true);
          }}
          handleState={handleState}
        />
        <OverflowDropDownList
          dropdownDynamic={true}
          breakPoints={wizardBreakPoints}
          isWizard={isWizard}
          price={true}
          listItems={[
            <Tooltip title="Show discount amount (percentage or fixed) on the Total Price">
              <span>
                <Button
                  className={discount ? '' : 'button-inactive'}
                  shape="round"
                  icon={discount ? <TickIcon style={{ color: '#2F2C88' }} /> : null}
                  text="Discount"
                  onClick={() => {
                    toggleDiscount();
                    clickDiscountBtn(disctBtn);
                    setDiscountBtn(!disctBtn);
                  }}
                />
              </span>
            </Tooltip>,
            <Tooltip title="Show tax amount (percentage or fixed) on the Total Price">
              <span>
                <Button
                  className={tax ? '' : 'button-inactive'}
                  shape="round"
                  icon={tax ? <TickIcon style={{ color: '#2F2C88' }} /> : null}
                  text="Tax"
                  onClick={() => {
                    toggleTax();
                    clickTaxBtn(taxBtn);
                    setTaxBtn(!taxBtn);
                  }}
                />
              </span>
            </Tooltip>,
            <Tooltip title="Display the number of your monthly or yearly payments">
              <span>
                <Button
                  className={quantity ? '' : 'button-inactive'}
                  shape="round"
                  icon={quantity ? <TickIcon style={{ color: '#2F2C88' }} /> : null}
                  text="Quantity"
                  onClick={toggleQuantity}
                />
              </span>
            </Tooltip>,
            <>
              {!isWizard && (
                <Tooltip
                  title={
                    selectable === 'locked'
                      ? `The prospect can't tick/untick them`
                      : selectable
                      ? `The prospect can choose the deliverables`
                      : `The prospect won't choose the deliverables`
                  }>
                  <span>
                    <Button
                      className={selectable ? '' : 'button-inactive'}
                      shape="round"
                      icon={
                        selectable === 'locked' ? (
                          <CloseLockIcon style={{ color: '#2F2C88' }} />
                        ) : selectable ? (
                          <OpenLockIcon style={{ color: '#2F2C88' }} />
                        ) : null
                      }
                      text="Checkboxes"
                      onClick={toggleSelectable}
                    />
                  </span>
                </Tooltip>
              )}
            </>,
          ]}
        />

        <span>
          <Divider className="pricing" type="vertical" />
        </span>
        <span>
          <Button
            className={openDrawer === 'all-options' ? '' : 'button-inactive'}
            shape="round"
            icon={<SlidersIcon style={{ color: '#2F2C88' }} />}
            text="View All"
            onClick={() => showPane('all-options')}
          />
        </span>
      </Col>
      <Col>
        <Radio.Group
          options={[
            { label: 'Table', value: 'table' },
            { label: 'Value', value: 'value' },
          ]}
          onChange={(e) => {
            dispatch({ type: 'setStab', stab: defaultStab, reset: true });
            save({ 'pricing.strategy': e.target.value }, true);
          }}
          value={prop.pricing.strategy}
          optionType="button"
        />
      </Col>
    </Row>
  );
};

TotalOptions.defaultProps = {
  selectable: false,
  discount: '',
  tax: '',
  quantity: false,
  defaultStab: '',
  roundTotal: false,
  isWizard: false,
  discountBtn: false,
};

TotalOptions.propTypes = {
  configText: PropTypes.func.isRequired,
  prop: PropTypes.instanceOf(Object).isRequired,
  save: PropTypes.func.isRequired,
  price: PropTypes.bool.isRequired,
  curr: PropTypes.string.isRequired,
  quantity: PropTypes.bool,
  tax: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  discount: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  roundTotal: PropTypes.bool,
  toggleDiscount: PropTypes.func.isRequired,
  toggleTax: PropTypes.func.isRequired,
  toggleRoundedTotal: PropTypes.func.isRequired,
  toggleQuantity: PropTypes.func.isRequired,
  toggleSelectable: PropTypes.func.isRequired,
  selectable: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  defaultStab: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  toggleSubtotal: PropTypes.func.isRequired,
  toggleTotal: PropTypes.func.isRequired,
  isWizard: PropTypes.bool,
  discountBtn: PropTypes.bool,
  taxBtn: PropTypes.bool,
  handleState: PropTypes.func.isRequired,
};

export default TotalOptions;
