import { Button, Form, Input, Modal, Row, Select, notification } from 'antd';
import ColorBox from 'components/ColorBox/ColorBox';
import PageHeader from 'components/PageHeader';
import './GeneralSettings.scss';
import { ActionButton } from 'pages/Emails/Components/Accordion';
import { useRef, useState } from 'react';
import Uploader from 'components/Uploader';
import { GET_EMAIL_SETTINGS } from 'graphql/queries/emailSettingsQueries';
import { UPDATE_EMAIL_SETTINGS } from 'graphql/mutations/emailSettingsMutations';
import { useMutation, useReactiveVar } from '@apollo/client';

import * as yup from 'yup';
import { cache, userVar } from 'graphql/cache';

const schema = yup.object().shape({
  font: yup.string().required('The font is required.'),
  replyTo: yup
    .string()
    .required('The reply to is required.')
    .email('Please enter a valid email address.'),
  senderName: yup.string().required('The sender name is required.'),
});

const yupSync = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value });
  },
};

const { Option } = Select;

export const GeneralSettingsModal = ({ isOpen, onCancel, data }) => {
  const user = useReactiveVar(userVar);
  const [form] = Form.useForm();
  const imageInputRef = useRef();
  const [logo, setLogo] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [imageUploading, setImageUploading] = useState(false);
  const [upload] = Uploader();

  const fonts = ['Poppins','PT+Serif','Assistant','Sacramento'];

  const [updateSettings, { loading }] = useMutation(UPDATE_EMAIL_SETTINGS);

  const onSettingUpdate = async (values) => {
    if (selectedFile) {
      setImageUploading(true);
      const { url } = await upload(selectedFile, `company-logo-for-email`);
      values.companyLogo = url;
      setImageUploading(false);
    } else {
      values.companyLogo = data.companyLogo;
    }
    updateSettings({
      variables: {
        settings: { generalSettings: values },
      },
      onCompleted: (response) => {
        notification.success({
          description: 'Sender settings updated successfully.',
        });
        const cacheEmailSettings = cache.readQuery({ query: GET_EMAIL_SETTINGS });
        if (cacheEmailSettings && response.updateEmailSettings) {
          cache.writeQuery({
            query: GET_EMAIL_SETTINGS,
            data: {
              fetchEmailSettings:  {
                ...cacheEmailSettings.fetchEmailSettings,
                ...response.updateEmailSettings,
              } ,
            },
          });
        }
        onCancel();
      },
    });
  };

  const onLogoChange = () => {
    imageInputRef.current.click();
  };

  const onImageSelect = async (event) => {
    event.preventDefault();
    const { files } = event.target;
    if (files && !files.length) return;
    setLogo(URL.createObjectURL(files[0]));
    setSelectedFile(files[0]);
  };

  const onReset = () => {
    form.resetFields();
    setLogo(data.companyLogo);
    setSelectedFile(null);
  };

  return isOpen ? (
    <Modal
      centered
      className="general-settings-modal"
      visible={isOpen}
      width={'530px'}
      onCancel={onCancel}
      footer={false}>
      <PageHeader pageTitle="Sender Settings" />
      <Form
        form={form}
        style={{ width: '100%' }}
        initialValues={data}
        layout="vertical"
        autoComplete="off"
        onFinish={onSettingUpdate}>
        <Row style={{ gap: '24px', flexDirection: 'column' }}>
          <Row style={{ gap: '8px', marginTop: '7px', flexDirection: 'column' }}>
            <span className="heading">Company Logo</span>
            <Row align="middle" style={{ gap: '8px' }}>
              <img alt="hello" className="company-logo" src={logo || data.companyLogo} />
              <ActionButton text="Change" icon={false} onClick={onLogoChange} />
            </Row>
          </Row>
          <Row style={{ gap: '48px' }}>
            <Row style={{ gap: '8px', flexDirection: 'column' }}>
              <span className="heading">Button Color</span>
              <Form.Item name="buttonColor" noStyle>
                <ColorSelection />
              </Form.Item>
            </Row>
            <Row style={{ gap: '8px', flexDirection: 'column' }}>
              <span className="heading">Button Text Color</span>
              <Form.Item name="buttonTextColor" noStyle>
                <ColorSelection />
              </Form.Item>
            </Row>
          </Row>
          <Form.Item name="font" rules={[yupSync]} label={<span className="heading">Font</span>}>
            <Select
              className="settings-global select-custom-suffix"
              value={'font a'}
              style={{ width: '100%' }}>
              {fonts.map(font => <Option key={font} value={font}>
                {font}
              </Option>)}
            </Select>
          </Form.Item>
          <Form.Item
            name="senderName"
            rules={[yupSync]}
            extra={
              <span className="sender-helper-text">
                The sender's name will be automatically populated from the 'Full Name' field on your
                profile page.
              </span>
            }
            label={<span className="heading">Sender Name Will Be</span>}>
            <Select
              className="settings-global select-custom-suffix"
              value={'font a'}
              disabled={user?.profile?.name?.split(' ').length < 2 &&  data.senderName === 'firstName' }
              style={{ width: '100%' }}>
              <Option key={'firstName'} value={'firstName'}>
                FIRST NAME
              </Option>
              <Option key={'lastName'} value={'lastName'}>
                LAST NAME
              </Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="replyTo"
            label={<span className="heading">Reply to</span>}
            rules={[yupSync]}>
            <Input />
          </Form.Item>
          <Row align="middle">
            <Button loading={loading || imageUploading} htmlType="submit" className="save-btn">
              Save Changes
            </Button>
            <Button
              disabled={loading || imageUploading}
              onClick={onReset}
              className="reset-btn"
              type="link">
              Reset
            </Button>
          </Row>
        </Row>
      </Form>
      <input
        type="file"
        ref={imageInputRef}
        style={{ display: 'none' }}
        onChange={onImageSelect}
        accept=".jpg, .jpeg, .png"
      />
    </Modal>
  ) : (
    <></>
  );
};

const ColorSelection = ({ value, onChange }) => {
  return (
    <ColorBox
      showColorName={false}
      style={{ width: '67px', height: '32px' }}
      color={value || '#fff'}
      onChange={onChange}
    />
  );
};
