import { Modal } from "antd";
import PageHeader from "components/PageHeader";
import {  ContentSection } from "pages/Emails/Components/Accordion";

export const CustomizeEmailSettingModal = ({
  isOpen,
  onCancel,
  emailKey,
  formData,
  generalSettings,
  config = {},
  onCustomize
}) => {
  return isOpen ? (
    <Modal
      centered
      className="customize-email-modal accordion-item"
      visible={isOpen}
      width={'564px'}
      onCancel={onCancel}
      footer={false}>
      <PageHeader pageTitle="Edit Template" />
      <ContentSection 
        withSidePreview={false}  
        emailKey={emailKey}
        config={{...config, isReminder: false}}
        generalSettings={generalSettings}
        data={formData}
        onSubmit={(values) => onCustomize(emailKey, values)}
      />
    </Modal>
  ) : (
    <></>
  );
};
