import getRaw from './getRaw';
import { hyphenatedWord } from 'utils/string';

const getSignature = ({ prop, user, configText }) => {
  const title = hyphenatedWord(configText('section signature title'));

  return {
    title,
    rawtitle: getRaw(title),
    myname: prop.name,
    rawmyname: getRaw(prop.name),
    name: prop?.client?.contact,
    rawname: getRaw(prop?.client?.contact),
    hidesig: user.hidesig,
    signature: user.signature || configText('section signature signature'),
  };
};

export default getSignature;
