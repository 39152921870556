import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';

import { GET_ALL_MEDIAS_LIBRARIES } from 'graphql/queries/contentLibraryQueries';
import helpers from 'helpers';
import GreenTickIcon from 'components/Icons/GreenTickIcon';
import HyphenRoundIcon from 'components/Icons/HyphenRoundIcon';

import './LibraryFolderItems.scss';

const LibraryFolderItems = ({ selectedFolder, selectedItems, addToSeletedItem }) => {
  const [images, setImages] = useState([]);

  const { data: mediasData } = useQuery(GET_ALL_MEDIAS_LIBRARIES, {
    variables: {
      type: 'medias',
      ...(selectedFolder === 'all-starred' ? { allStarred: true } : { key: selectedFolder }),
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (mediasData?.fetchContentLibraryMediasItems) {
      let _medias = mediasData.fetchContentLibraryMediasItems?.slice();
      _medias = _medias.filter((m) => helpers.isImage(m.src));
      setImages([..._medias]);
    }
  }, [mediasData]);

  const handleImageLoad = (event) => {
    const { naturalWidth, naturalHeight, id } = event.target;

    setImages((prev) => {
      const newData = [...prev];

      // find the photo
      const photoIndex = newData.findIndex((p) => p._id === id);

      // if width is not available then add width
      if (photoIndex !== -1 && !newData[photoIndex]?.width) {
        const newPhoto = {
          ...newData[photoIndex],
          width: naturalWidth,
          height: naturalHeight,
        };
        newData[photoIndex] = newPhoto;
      }

      return newData;
    });
  };

  return (
    <Row gutter={[12, 12]} className="library-folder-items">
      {images.map((m) => (
        <Col key={m._id} span={12} className="folder-item">
          {selectedItems[m.src] && (
            <>
              <HyphenRoundIcon onClick={() => addToSeletedItem(m)} className="remove-icon" />
              <GreenTickIcon className="tick-icon" />
            </>
          )}

          <img
            width="100%"
            height="100px"
            src={m.src}
            alt={m.name}
            className="item-img"
            onClick={() => addToSeletedItem(m)}
            id={m._id}
            onLoad={handleImageLoad}
          />
        </Col>
      ))}
    </Row>
  );
};

export default LibraryFolderItems;
