import { EditorState, genKey, Modifier } from 'draft-js';
import { getSelectionRange, getSelectedBlockElement, getSelectionCoords } from './selection';
import _ from 'lodash';

import createCellBlock from './createCellBlock';

const editorChange = ({
  editorState,
  force = false,
  props,
  dispatch,
  updateSelection,
  editorCallback,
  promptForVariable,
  setOpenVariablesList,
  setScrollingTo,
  setActiveEditorId,
  editorRef,
  focusChanged,
}) => {
  const getWord = (text, anchor) => {
    if (!text) return;

    let tempIndex = [0, 0];
    for (let i = anchor - 1; i >= 0; i--) {
      if (text[i] === ' ') {
        tempIndex[0] = i;
        break;
      }
      if (text[i] === '{' && text[i - 1] === '{') {
        tempIndex[0] = i - 1;
        break;
      }
    }
    for (let i = anchor; i < text.length; i++) {
      if (text[i] === ' ') {
        tempIndex[1] = i;
        break;
      }
      if (text[i] === '}' && text[i + 1] === '}') {
        tempIndex[1] = i + 2;
        break;
      }
    }
    if (tempIndex[1] === 0) {
      tempIndex[1] = text.length + 1;
    }
    const tempText = text.substring(tempIndex[0], tempIndex[1]);
    return tempText;
  };

  const getVariablesState = (invalidate = true) => {
    if (!invalidate) {
      return null;
    }

    const currentSelection = editorState.getSelection();

    const anchorKey = currentSelection.getAnchorKey();

    const currentContent = editorState.getCurrentContent();

    const currentContentBlock = currentContent.getBlockForKey(anchorKey);

    const offset = currentSelection.getStartOffset();

    let text = currentContentBlock?.getText() || '';

    const currentText = getWord(text, offset);
    if (currentText?.indexOf('{{') > -1) {
      if (currentText === '{{') {
        const selectionCoords = getSelectionCoords(props.id);
        dispatch({
          type: 'setInlineToolbar',
          inlineToolbar: {
            show: true,
            position:
              language !== 'hebrew'
                ? {
                    top: selectionCoords?.offsetTop + 70,
                    left: selectionCoords?.offsetLeft + 60,
                  }
                : {
                    top: selectionCoords?.offsetTop + 70,
                    right: selectionCoords?.offsetRight + 60,
                  },
          },
        });
        if (promptForVariable) {
          promptForVariable(editorState, false, currentText);
        }
      } else {
        if (promptForVariable) {
          promptForVariable(editorState, true, currentText);
        }
      }
    } else {
      if (setOpenVariablesList) {
        setOpenVariablesList(false);
      }
      if (typeof setScrollingTo === 'function') {
        setScrollingTo('');
      }
      dispatch({ type: 'setVariableState', variableState: null });
      dispatch({ type: 'setDisplayVariableInput', displayVariableInput: false });
    }
  };

  // fix tables from things like cut:
  if (props.id === 'pricing_text' || props.id === 'milestones_text') {
    const selection = editorState?.getSelection();
    const content = editorState?.getCurrentContent();

    const blockMap = content.getBlockMap();

    const badblock0 = blockMap
      .toSeq()
      .find(
        (v) =>
          v.getType() === 'ordered-list-item' &&
          v.getData() &&
          v.getData().get('class') &&
          v.getData().get('class').indexOf('right') !== -1 &&
          ((content.getBlockBefore(v.getKey()) &&
            content.getBlockBefore(v.getKey()).getType() !== 'ordered-list-item') ||
            (content.getBlockBefore(v.getKey()) &&
              content.getBlockBefore(v.getKey()).getData() &&
              content.getBlockBefore(v.getKey()).getData().get('class') &&
              content.getBlockBefore(v.getKey()).getData().get('class').indexOf('left') === -1))
      );

    const badblock1 = blockMap
      .toSeq()
      .find(
        (v) =>
          v.getType() === 'ordered-list-item' &&
          v.getData() &&
          v.getData().get('class') &&
          v.getData().get('class').indexOf('right') !== -1 &&
          content.getBlockAfter(v.getKey()) &&
          content.getBlockAfter(v.getKey()).getData() &&
          content.getBlockAfter(v.getKey()).getData().get('class') &&
          content.getBlockAfter(v.getKey()).getData().get('class').indexOf('right') !== -1
      );

    const badblock2 = blockMap
      .toSeq()
      .find(
        (v) =>
          v.getType() === 'ordered-list-item' &&
          v.getData() &&
          v.getData().get('class') &&
          v.getData().get('class').indexOf('left') !== -1 &&
          content.getBlockAfter(v.getKey()) &&
          content.getBlockAfter(v.getKey()).getData() &&
          content.getBlockAfter(v.getKey()).getData().get('class') &&
          content.getBlockAfter(v.getKey()).getData().get('class').indexOf('left') !== -1
      );

    if (badblock0) {
      // double left block - putting right block after
      const ckey = badblock0.getKey();
      const b1 = createCellBlock(genKey(), '', 'tablecell left');

      const blocksBefore = blockMap.toSeq().takeUntil((v) => v === badblock0);
      const blocksAfter = blockMap
        .toSeq()
        .skipUntil((v) => v === badblock0)
        .rest();

      console.log('before/after', blocksBefore.toOrderedMap(), blocksAfter.toOrderedMap());

      const newBlocks = blocksBefore
        .concat(
          [
            [b1.getKey(), b1],
            [badblock0.getKey(), badblock0],
          ],
          blocksAfter
        )
        .toOrderedMap();

      const newcont = content.merge({
        blockMap: newBlocks,
        selectionBefore: selection,
        selectionAfter: selection.merge({
          anchorKey: ckey,
          anchorOffset: 0,
          focusKey: ckey,
          focusOffset: 0,
          isBackward: false,
        }),
      });

      const newEditorState = EditorState.push(editorState, newcont, 'fix-table');
      editorRef.current = newEditorState;

      dispatch({
        type: 'setEditorState',
        editorState: newEditorState,
      });
      return;
    }

    if (badblock2) {
      // double left block - putting right block after
      const ckey = badblock2.getKey();
      const b1 = createCellBlock(genKey(), '', 'tablecell right');

      const blocksBefore = blockMap.toSeq().takeUntil((v) => v === badblock2);
      const blocksAfter = blockMap
        .toSeq()
        .skipUntil((v) => v === badblock2)
        .rest();

      console.log('before/after', blocksBefore.toOrderedMap(), blocksAfter.toOrderedMap());

      const newBlocks = blocksBefore
        .concat(
          [
            [badblock2.getKey(), badblock2],
            [b1.getKey(), b1],
          ],
          blocksAfter
        )
        .toOrderedMap();

      const newcont = content.merge({
        blockMap: newBlocks,
        selectionBefore: selection,
        selectionAfter: selection.merge({
          anchorKey: ckey,
          anchorOffset: 0,
          focusKey: ckey,
          focusOffset: 0,
          isBackward: false,
        }),
      });

      const newEditorState = EditorState.push(editorState, newcont, 'fix-table');
      editorRef.current = newEditorState;

      dispatch({
        type: 'setEditorState',
        editorState: newEditorState,
      });
      return;
    }

    if (badblock1) {
      // double left block - putting left block after
      const ckey = badblock1.getKey();
      const b1 = createCellBlock(genKey(), '', 'tablecell left');

      const blocksBefore = blockMap.toSeq().takeUntil((v) => v === badblock1);
      const blocksAfter = blockMap
        .toSeq()
        .skipUntil((v) => v === badblock1)
        .rest();

      console.log('before/after', blocksBefore.toOrderedMap(), blocksAfter.toOrderedMap());

      const newBlocks = blocksBefore
        .concat(
          [
            [badblock1.getKey(), badblock1],
            [b1.getKey(), b1],
          ],
          blocksAfter
        )
        .toOrderedMap();

      const newcont = content.merge({
        blockMap: newBlocks,
        selectionBefore: selection,
        selectionAfter: selection.merge({
          anchorKey: ckey,
          anchorOffset: 0,
          focusKey: ckey,
          focusOffset: 0,
          isBackward: false,
        }),
      });

      const newEditorState = EditorState.push(editorState, newcont, 'fix-table');
      editorRef.current = newEditorState;

      dispatch({
        type: 'setEditorState',
        editorState: newEditorState,
      });
      return;
    }
  }

  const language = props?.prop?.language || 'english';

  const selectionCoords = getSelectionCoords(props.id);

  if (setActiveEditorId) {
    // set the editor ID so we can use it to display one variable/other menu at a time
    setActiveEditorId(props.id);
  }

  if (selectionCoords) {
    const maxOffset = 220;
    if (language !== 'hebrew') {
      selectionCoords.offsetLeft = Math.min(selectionCoords.offsetLeft, maxOffset);
    } else {
      selectionCoords.offsetRight = Math.min(selectionCoords.offsetRight, maxOffset);
    }
    dispatch({
      type: 'setCurrentCursorOffset',
      currentCursorOffset: {
        show: true,
        position:
          language !== 'hebrew'
            ? {
                top: selectionCoords.offsetTop,
                left: selectionCoords.offsetLeft,
              }
            : {
                top: selectionCoords.offsetTop,
                right: selectionCoords.offsetRight,
              },
      },
    });
  }

  if (
    editorState?.getSelection()?.getHasFocus() ||
    (!editorState?.getSelection()?.isCollapsed() && !force)
  ) {
    if (selectionCoords) {
      dispatch({
        type: 'setInlineToolbar',
        inlineToolbar: {
          show: true,
          position:
            language !== 'hebrew'
              ? {
                  top: selectionCoords.offsetTop,
                  left: selectionCoords.offsetLeft,
                  bottom: selectionCoords.offsetBottom,
                }
              : {
                  top: selectionCoords.offsetTop,
                  right: selectionCoords.offsetRight,
                  bottom: selectionCoords.offsetBottom,
                },
        },
      });
    }
  } else {
    dispatch({
      type: 'setInlineToolbar',
      inlineToolbar: {
        show: false,
      },
    });
  }

  setTimeout(
    () =>
      updateSelection({
        id: `r_${props.id}`,
        dispatch,
        getSelectionRange,
        getSelectedBlockElement,
      }),
    0
  );

  getVariablesState();

  try {
    const styles = ['bg-rgba(24, 144, 255, 1)', 'rgba(255, 255, 255, 1)'];

    const contentWithoutStyles = _.reduce(
      styles,
      (newContentState, style) =>
        Modifier.removeInlineStyle(newContentState, editorState.getSelection(), style),
      editorState.getCurrentContent()
    );

    const newEditorState = EditorState.push(
      editorState,
      contentWithoutStyles,
      'change-inline-style'
    );
    editorState = newEditorState;
  } catch (error) {
    console.log('No Style');
  }

  editorRef.current = editorState;
  dispatch({ type: 'setEditorState', editorState });

  if (!focusChanged) {
    props.saveDraft(editorState, () => {
      editorCallback && editorCallback();
      EditorState.forceSelection(editorState, editorState?.getSelection());
    });
  }
};

export default editorChange;
