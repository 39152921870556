import React from 'react';
import { Button, Modal, Col, Row } from 'antd';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import CircleTickIcon from 'components/Icons/CircleTickIcon';

import images from 'constants/images';
import { FEATURES_LIST_1, FEATURES_LIST_2 } from 'constants/index';

import './TrialEndPopup.scss';

const renderFeaturesList = (list) => {
  return list.map((item) => (
    <Row key={item.text} className="feature-list">
      <CircleTickIcon className="feature-list-check-icon" />
      <div className="feature-list-info">
        <span className="feature-list-text">{item.text}</span>
      </div>
    </Row>
  ));
};

const TrialEndPopup = ({ everPayed, userLockedPopup, setUserLockedPopup }) => {
  const history = useHistory();

  return (
    <>
      <Modal
        className="trial-end-popup"
        centered
        visible={!userLockedPopup}
        onCancel={() => setUserLockedPopup(true)}
        closable={null}
        footer={null}>
        <div className="popup-left">
          <div className="trial-left-header">
            <img className="logo" src={images.LOGO_LIGHT} alt="prospero-logo" />
          </div>
          <div className="body">
            <div className="design-img">
              <img src={images.DESIGN_IMG_1} alt="design-img" />
            </div>
            <div className="design-img">
              <img src={images.DESIGN_IMG_2} alt="design-img" />
            </div>
            <div className="design-img">
              <img src={images.DESIGN_IMG_3} alt="design-img" />
            </div>
            <div className="design-img">
              <img src={images.DESIGN_IMG_4} alt="design-img" />
            </div>
            <div className="design-img">
              <img src={images.DESIGN_IMG_5} alt="design-img" />
            </div>
          </div>
        </div>
        <div className="popup-right">
          <div className="trial-right-header">
            <h3 className="title">{`🔓 Your ${
              everPayed ? 'Subscription' : 'Trial'
            } Has Ended!`}</h3>
            <p className="sub-title">{`Your ${
              everPayed ? 'subscription has ended,' : 'free trial period is over,'
            } but your journey doesn't have to be. Upgrade now to continue enjoying all the powerful features that help you.`}</p>
          </div>
          <div className="body">
            <p className="information-header">Here's what you'll gain by upgrading:</p>
            <Row className="plans-information">
              <Col>{renderFeaturesList(FEATURES_LIST_1)}</Col>
              <Col>{renderFeaturesList(FEATURES_LIST_2)}</Col>
            </Row>
          </div>
          <div className="button-wrapper">
            <Button
              className="upgrade-button"
              type="primary"
              onClick={() => {
                setUserLockedPopup(true);
                history.push('/plans');
              }}>
              {'Upgrade Now'}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

TrialEndPopup.defaultProps = {
  everPayed: false,
  userLockedPopup: false,
};

TrialEndPopup.propTypes = {
  everPayed: PropTypes.bool,
  userLockedPopup: PropTypes.bool,
};

export default TrialEndPopup;
