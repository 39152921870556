import React, { useEffect } from 'react';
import { Tooltip } from 'antd';

import NotificationIcon from 'components/Icons/NotificationIcon';

const NotificationItem = () => {
  useEffect(() => {
    window.HW_config = {
      selector: '#notification-item', // CSS selector where to inject the badge
      account: 'JVD2Dx',
    };

    const element = document.getElementById('headwayappScript');
    if (!element) {
      const script = document.createElement('script');
      script.src = 'https://cdn.headwayapp.co/widget.js';
      script.async = true;
      script.id = 'headwayappScript';
      document.body.appendChild(script);
    }

    return () => {
      if (element) {
        element.parentElement.removeChild(element);
      }
      const badge = document.getElementById('HW_badge_cont');
      if (badge) {
        badge.parentElement.removeChild(badge);
      }
    };
  }, []);

  return (
    <Tooltip placement="right" title="What's New" color="white">
      <div className="option-item headway-widget" id="notification-item">
        <NotificationIcon />
      </div>
    </Tooltip>
  );
};

export default NotificationItem;
