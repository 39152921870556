import { Button, Divider, Modal, Row } from 'antd';
import PageHeader from 'components/PageHeader';

import './Preview.scss';
import { PreviewContent } from './Preview';
import { AttachmentIcon, DriveLogo, EditIcon2, SendIcon } from 'components/Icons';
import { useMemo, useState } from 'react';
import { TestMailModal } from 'pages/Emails/Modals/TestMail';
import { useReactiveVar } from '@apollo/client';
import { userVar } from 'graphql/cache';

export const EmailPreviewModal = ({
  isOpen,
  onCancel,
  generalSettings,
  emailKey,
  userName = '',
  formData,
  hasAttachment = false,
  staticHeader = false,
  onCustomizeEmail,
}) => {
  const user = useReactiveVar(userVar);
  const [testMailModal, setTestMailModal] = useState(false);
  const onTestEmail = () => setTestMailModal(true);
  const senderFormattedName = useMemo(() => {
    const nameParts =  user?.profile?.name?.trim().split(' ');
    if (!nameParts && nameParts.length === 0) {
      return '';
    }
    const firstName = nameParts[0].charAt(0).toUpperCase() + nameParts[0].slice(1).toLowerCase();
    const lastName =
      nameParts.length > 1
        ? nameParts[nameParts.length - 1].charAt(0).toUpperCase() +
          nameParts[nameParts.length - 1].slice(1).toLowerCase()
        : undefined;
    if (generalSettings?.senderName === 'firstName') {
      return firstName;
    }
    return lastName || firstName;
  }, [generalSettings?.senderName, user?.profile?.name]);


  return isOpen ? (
    <>
      <Modal
        centered
        className="email-preview-modal"
        visible={isOpen}
        width={'850px'}
        onCancel={onCancel}
        footer={false}>
        <div className="header-section">
          <PageHeader
            pageTitle="Email Preview"
            showButton={
              staticHeader ? (
                <>
                  <Button icon={<SendIcon />} onClick={onTestEmail}>
                    Test Email
                  </Button>
                  <Button icon={<EditIcon2 />} onClick={onCustomizeEmail}>
                    Customize Email
                  </Button>
                </>
              ) : null
            }
          />
        </div>
        <div className="scrollable-section">
          <Row className="subject-section">
            <Row className="subject" style={{ gap: '8px' }}>
              <span>Subject:</span>
              <span>{formData?.subjectLine?.value}</span>
            </Row>
            <Row className="user" style={{ gap: '8px' }}>
              <span>From:</span>
              <span>{senderFormattedName} via Prospero</span>
            </Row>
          </Row>
          <PreviewContent
            generalSettings={generalSettings}
            formData={formData}
            emailKey={emailKey}
            showPowerBy={true}
          />
          <FooterSection hasAttachment={hasAttachment} />
        </div>
      </Modal>
      <TestMailModal
        isOpen={testMailModal}
        onCancel={() => setTestMailModal(false)}
        emailKey={emailKey}
        formData={formData}
      />
    </>
  ) : (
    <></>
  );
};

export const FooterSection = ({ hasAttachment }) => {
  return (
    <Row className="footer-section">
      <Row style={{ gap: '8px', flexDirection: 'column' }}>
        <div className="footer-heading">Do not share this email</div>
        <div className="footer-description">
          This email may contain sensitive information intended for your team's eyes only. Need help
          with the document? You can reply to this email.
        </div>
      </Row>
      <Row style={{ gap: '8px', flexDirection: 'column' }}>
        <div className="footer-heading">About Prospero</div>
        <div className="footer-description">
          Prospero is a proposal software designed to help businesses create and manage proposals
          more efficiently. It streamlines the proposal creation process, allowing to easily
          generate professional and customized proposals and documents, and get them signed from
          anywhere, anytime.
        </div>
      </Row>
      <Row style={{ gap: '8px', flexDirection: 'column' }}>
        <div className="footer-description">
          If you no longer wish to receive this email, change your email preferences here.
        </div>
      </Row>
      {hasAttachment && <Divider style={{ marginBottom: '0' }} />}
      {hasAttachment && <AttachmentSection />}
    </Row>
  );
};

const AttachmentSection = () => {
  return (
    <Row style={{ gap: '8px', flexDirection: 'column' }} align="top">
      <Row justify="space-between" style={{ alignSelf: 'stretch' }}>
        <Row className="attachment-title">
          <span>One attachment •&nbsp;</span>
          <span>Scanned by Gmail</span>
        </Row>
        <DriveLogo />
      </Row>
      <AttachmentIcon />
    </Row>
  );
};
