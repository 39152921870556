import { gql } from '@apollo/client';

export const UPDATE_EMAIL_SETTINGS = gql`
  mutation UpdateEmailSettings($settings: CampaignEmailsInput!) {
    updateEmailSettings(settings: $settings) {
      _id
      generalSettings {
        companyLogo 
        buttonColor
        buttonTextColor
        replyTo
        senderName
        font
      }
      publishedProposalToProspect {
        subjectLine {
          value
          RTL
        }
        previewText {
          value
          RTL
        }
        emailBody {
          value
          RTL
        }
        reminder
        sent
        clicked
        open
        buttonText {
          value
          RTL
        }
      }
      reminderToProspect {
          subjectLine {
          value
          RTL
        }
        previewText {
          value
          RTL
        }
        emailBody {
          value
          RTL
        }
        reminder
        sent
        clicked
        open
        buttonText {
          value
          RTL
        }
      }
      secondReminderToProspect {
          subjectLine {
          value
          RTL
        }
        previewText {
          value
          RTL
        }
        emailBody {
          value
          RTL
        }
        reminder
        sent
        clicked
        open
        buttonText {
          value
          RTL
        }
      }
      signedPDFToProspect {
         subjectLine {
          value
          RTL
        }
        previewText {
          value
          RTL
        }
        emailBody {
          value
          RTL
        }
        reminder
        sent
        clicked
        open
        buttonText {
          value
          RTL
        }
      }
    }
  }
`;

export const SEND_TEST_EMAIL = gql`
  mutation sendTestMail($emailType: String!,$email: String!, $template: EmailDataInput!) {
    sendTestMail(emailType: $emailType, template: $template, email: $email)     
  }
`;
