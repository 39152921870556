import React, { useState } from 'react';
import { Row, Form, Input, notification } from 'antd';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';

import Popconfirm from 'components/PopConfirm';
import ContentLibraryTree from 'pages/ContentLibrary/components/ContentLibraryTree/ContentLibraryTree';

import { CONTENT_LIBRARY_ADD_ITEM } from 'graphql/mutations/contentLibraryMutations';
import { getProposalTitle } from 'helpers/proposal';
import { userVar } from 'graphql/cache';

import './MoveLibraryModal.scss';

const MoveLibraryModal = ({
  visible,
  onCancel,
  template,
  selectedFolderKey,
  refetchTemplates,
  isDashboard,
  refetchContentLibraryTree,
  isTemplate,
}) => {
  const [toFolderKey, setToFolderKey] = useState(selectedFolderKey);
  const [moveLibraryError, setMoveLibraryError] = useState('');
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const [addItem] = useMutation(CONTENT_LIBRARY_ADD_ITEM, {
    onCompleted() {
      notification.success({
        description: isDashboard
          ? isTemplate
            ? `Template saved successfully to ${toFolderKey?.name || 'All Templates'}.`
            : 'Template saved successfully.'
          : 'Item moved successfully.',
      });
      if (refetchTemplates) {
        refetchTemplates();
      }
      setToFolderKey('');
      onCancel();
      form.resetFields();
      setLoading(false);
    },
    onError() {
      setLoading(false);
      setMoveLibraryError(isDashboard ? 'Template save failed.' : 'Item moved failed.');
    },
  });

  const onSelectTreeItem = (selectedTree) => {
    if (selectedTree.key === 'newTemplateFolder') {
      setLoading(selectedTree.value);
      if (!selectedTree.value) refetchContentLibraryTree();
    }
    if (selectedTree.key) setMoveLibraryError('');
    // else setMoveLibraryError('Select Folder Required!');

    setToFolderKey(selectedTree);
  };

  const handleSubmit = () => {
    if (isDashboard) {
      form
        .validateFields()
        .then((values) => {
          setLoading(true);
          addItem({
            variables: {
              fromKey: selectedFolderKey || '',
              id: toFolderKey.treeId || '',
              key: toFolderKey.key || '',
              item_id: template._id,
              newTemplateName: values.newTemplateName,
              type: isTemplate ? 'template' : 'proposal',
            },
          });
        })
        .catch((info) => {
          console.log('Validate Failed:', info);
        });
    } else {
      // if (!toFolderKey.key) {
      //   return setMoveLibraryError('Select Folder Required!');
      // }

      setLoading(true);
      addItem({
        variables: {
          fromKey: selectedFolderKey || '',
          id: toFolderKey.treeId,
          key: toFolderKey.key,
          item_id: template._id,
          type: isTemplate ? 'template' : 'template',
        },
      });
    }
  };

  return (
    <Popconfirm
      modalClassName={`move-to-folder-modal ${
        template?.language === 'hebrew' || template?.language === 'arabic' ? 'hebrew' : ''
      }`}
      title={`${isDashboard ? 'Save' : 'Move'} ${template ? 'Template' : 'Cover'}`}
      body={
        <div>
          {isDashboard && (
            <Row className="move-to-library-input">
              <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                initialValues={{
                  modifier: 'public',
                  newTemplateName: `${
                    isTemplate
                      ? `Copy of ${
                          template.templateName ? template.templateName : template.project.name
                        }`
                      : `${getProposalTitle({ proposal: template })}-Template`
                  }`,
                }}>
                <Form.Item
                  name="newTemplateName"
                  rules={[{ required: true, message: 'Template name is required' }]}>
                  <Input />
                </Form.Item>
              </Form>
            </Row>
          )}

          <Row className="move-to-library-list">
            <ContentLibraryTree
              fromModal={true}
              onSelect={() => {}}
              onSelectTreeItem={onSelectTreeItem}
              type="templates"
              onUpdate={() => {}}
              searchActive={true}
              toFolderKey={toFolderKey}
              userId={userVar()._id}
              isDashboard={isDashboard}
            />
          </Row>

          <p className="error">{moveLibraryError}</p>
        </div>
      }
      visible={visible}
      cancelText="CANCEL"
      confirmText={isDashboard ? 'SAVE' : 'MOVE'}
      onCancel={onCancel}
      onConfirm={handleSubmit}
      okButtonProps={{ loading }}
    />
  );
};

MoveLibraryModal.defaultProps = {
  visible: false,
  template: '',
  selectedFolderKey: '',
  refetchTemplates: () => {},
  isDashboard: false,
  refetchContentLibraryTree: () => {},
};

MoveLibraryModal.propTypes = {
  visible: PropTypes.bool,
  template: PropTypes.instanceOf(Object),
  onCancel: PropTypes.func.isRequired,
  selectedFolderKey: PropTypes.string,
  refetchTemplates: PropTypes.func,
  isDashboard: PropTypes.bool,
  refetchContentLibraryTree: PropTypes.func,
};

export default MoveLibraryModal;
