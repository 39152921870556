import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Form, Input, Row, Col, Button, message } from 'antd';
import { useMutation } from '@apollo/client';

import WhatsNew from 'components/WhatsNew/WhatsNew';
import Path from 'routes/path';
import { USER_FORGOT_PASSWORD } from 'graphql/mutations/userMutations';
import images from 'constants/images';

import './ForgotPassword.scss';

const ForgotPassword = () => {
  const [form] = Form.useForm();

  const [forgotPassword] = useMutation(USER_FORGOT_PASSWORD, {
    async onCompleted(data) {
      message.success('Reset password link sent successfully');
      form.resetFields();
    },
    onError() {
      message.success('Reset password link sent successfully');
      form.resetFields();
    },
  });

  useEffect(() => {
    document.title = 'Prospero - Forgot Password';
  });

  const doSubmit = (values) => {
    const { email } = values;
    if (email) {
      forgotPassword({
        variables: {
          email: email.toLowerCase(),
        },
      });
    }
  };

  return (
    <Row className="forgot-container">
      <Col className="forgot-left">
        <Row className="header-forgot">
          <Col className="logo">
            <NavLink to={Path.HOME}>
              <img src={images.LOGO} alt="prospero-logo" height="20" />
            </NavLink>
          </Col>
        </Row>
        <div className="content-wrapper">
          <div className="heading-wrap">
            <h1 className="heading">Forgot password?</h1>
            <p className="sub-heading-text">
              Enter the email address you used to create your Prospero account. We'll send you a
              password reset email.
            </p>
            <div className="color-divider" />
          </div>
          <div className="error-message"></div>
          <Form layout="vertical email-input" form={form} onFinish={doSubmit}>
            <Form.Item
              label="Email"
              name="email"
              labelCol={false}
              rules={[
                { required: true, message: 'Please input your email' },
                {
                  type: 'email',
                  message: 'Please input a valid email',
                },
              ]}>
              <Input placeholder="email@example.com" />
            </Form.Item>
            <Button type="primary" htmlType="submit" size="large" block className="forgot-btn">
              <span className="spacer" />
              <span className="text-content">SEND RESET EMAIL</span>
            </Button>
          </Form>
          <div className="forgot-link">
            Take me back to <NavLink to={Path.LOGIN}>Log In</NavLink>
          </div>
        </div>
      </Col>
      <Col className="forgot-right">
        <WhatsNew />
      </Col>
    </Row>
  );
};

ForgotPassword.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default ForgotPassword;
