import React, { useState, useEffect } from 'react';
import { Button, Layout, Divider, Input, Dropdown, Menu, Tooltip, Radio } from 'antd';
import { NavLink, useHistory } from 'react-router-dom';
import { CopyOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import PreviewIcon from 'components/Icons/PreviewIcon';
import SavingIcon from 'components/Icons/SavingIcon';
import SavedIcon from 'components/Icons/SavedIcon';
import DownloadIcon from 'components/Icons/DownloadIcon';
import BackIcon from 'components/Icons/BackIcon';
import DropdownIcon from 'components/Icons/DropdownIcon';
import MessageIcon from 'components/Icons/MessageIcon';
import WhatsappIcon from 'components/Icons/WhatsappIcon';
import DesktopIcon from 'components/Icons/DesktopIcon';
import MobileIcon from 'components/Icons/MobileIcon';
import ResetIcon from 'components/Icons/ResetIcon';
import SaveIcon from 'components/Icons/SaveIcon';
import NewCopyDarkIcon from 'components/Icons/NewCopyDarkIcon';
import images from 'constants/images';
import { KeyboardButton } from 'components/Icons';
import { SettingsModal, IntegrationModal } from './components';

import './ProposalHeaderBar.scss';

const { Header } = Layout;

const ProposalHeaderBar = ({
  mode,
  published,
  isPublishing,
  proposal,
  saveProposal,
  publishProposal,
  isSaving,
  openPublishView,
  copyPropsalLink,
  publishedProposalLink,
  downloadPdf,
  user,
  isTemplate,
  isSection,
  updateScreen,
  screen,
  handleManualSave,
  disablePreview,
  configText,
  updateLoaderState,
  propRef,
  setProp,
  scrollToSection,
  path,
  setKeyboardModal,
  buttons,
}) => {
  const history = useHistory();
  const [showShortcut, setShowShortcut] = useState(false);

  useEffect(() => {
    // open the keyboard shortcut modal on ctrl + shift + s
    const handleKeyPress = (e) => {
      if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.keyCode === 83) {
        e.preventDefault();
        setKeyboardModal((prev) => !prev);
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    // for ipad and smaller screen hide
    const isiPad = /iPad/i.test(navigator.userAgent);
    setShowShortcut(!isiPad && window.innerWidth > 700 && !!setKeyboardModal);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const publishShareOptions = [
    {
      type: 'mail',
      title: 'Share Via Email',
      icon: <MessageIcon />,
      link: () =>
        window.open(
          `mailto:${proposal.email}?body=${encodeURIComponent(
            'Proposal Link:' + publishedProposalLink()
          )}`,
          '_blank'
        ),
    },
    {
      type: 'whatsapp',
      title: 'Share Via WhatsApp',
      icon: <WhatsappIcon />,
      link: () =>
        window.open(`https://web.whatsapp.com/send?text=${publishedProposalLink()}`, '_blank'),
    },
  ];

  const publishButtonMenu = (
    <Menu className="publish-button-menu">
      {publishShareOptions.map((publishShareOption, publishShareOptionIndex) => (
        <Menu.Item key={publishShareOptionIndex} onClick={publishShareOption.link}>
          {publishShareOption.icon}
          <span className="publish-button-menu-title">{publishShareOption.title}</span>
        </Menu.Item>
      ))}
      <Menu.Item
        key="unshortened"
        onClick={() => copyPropsalLink('unshortened')}
        disabled={proposal.state === 'deleted'}>
        <CopyOutlined className="copy-unshortened-link" />
        <span className="publish-button-menu-title">Copy Unshortened Link</span>
      </Menu.Item>
      <Menu.Item
        key="unpublish"
        className="unpublish-button"
        loading={isPublishing}
        onClick={() => publishProposal(false)}
        disabled={proposal.state === 'deleted'}>
        <ResetIcon />
        <span className="publish-button-menu-title">Unpublish</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <Header className="proposal-header">
      <NavLink
        to={isTemplate || isSection ? `/contentlibrary?tab=${isSection ? '3' : '1'}` : '/'}
        className="prospero-logo">
        <img src={images.LOGO_SMALL} alt="proposal-logo-small" />
      </NavLink>

      {['draft', 'publish'].includes(mode) && <div className="seprator" />}

      {mode === 'wizard' && buttons}

      {mode === 'draft' && (
        <React.Fragment>
          <div className="btn-left">
            {showShortcut && (
              <Button
                className="header-btn"
                icon={<KeyboardButton />}
                onClick={() => setKeyboardModal(true)}
                aria-hidden="true">
                Shortcuts
              </Button>
            )}
          </div>

          <div className="spacer" />

          <Tooltip title={isSaving ? 'Saving' : 'Saved'}>
            {isSaving ? <SavingIcon /> : <SavedIcon />}
          </Tooltip>

          <Tooltip title="Save" className="save-proposal" onClick={handleManualSave}>
            <SaveIcon />
          </Tooltip>

          {!isSection && <div className="seprator" />}

          {proposal && !isSection && (
            <IntegrationModal
              user={user}
              proposal={proposal}
              saveProposal={saveProposal}
              isSaving={isSaving}
            />
          )}

          <Divider type="vertical" />

          {proposal && !isSection && (
            <SettingsModal
              user={user}
              proposal={proposal}
              saveProposal={saveProposal}
              updateLoaderState={updateLoaderState}
              scrollToSection={scrollToSection}
              configText={configText}
              propRef={propRef}
              setProp={setProp}
              path={path}
            />
          )}

          {!isSection && (
            <>
              <Divider type="vertical" />
              <Button
                className="settings-button right"
                icon={<DownloadIcon />}
                onClick={downloadPdf}
                aria-hidden="true">
                Download PDF
              </Button>
              <div className="seprator" />
            </>
          )}

          <Button
            className="preview-button preview-button-right"
            onClick={() => {
              if (disablePreview !== 'saving-layout' && openPublishView) {
                openPublishView();
              }
            }}
            style={{ marginLeft: '24px !important' }}
            disabled={isSaving}
            loading={isSaving}>
            <span className="link-text only-mobile">PREVIEW</span>
            <span className="link-text only-desktop">PREVIEW & SAVE</span>
            <PreviewIcon />
          </Button>
        </React.Fragment>
      )}

      {mode === 'publish' && (
        <React.Fragment>
          <Button
            className="back-button cancel"
            type="primary"
            onClick={() => history.goBack()}
            icon={<BackIcon />}>
            BACK
          </Button>

          <div className="spacer" />

          <div className="switch-toggle-button toggle-mobile-desktop">
            <Radio.Group
              value={screen}
              onChange={() => {
                screen === 'desktop' ? updateScreen('mobile') : updateScreen('desktop');
              }}>
              <Radio.Button value="desktop">
                <DesktopIcon />
                <span className="switch-toggle-button-text"> DESKTOP </span>
              </Radio.Button>
              <Radio.Button value="mobile">
                <MobileIcon />
                <span className="switch-toggle-button-text"> MOBILE </span>
              </Radio.Button>
            </Radio.Group>
          </div>

          <div className="seprator" />

          {published ? (
            <>
              <div className="proposal-link-container">
                <Input
                  id="proposal-link-input"
                  name="proposal-link-input"
                  type="text"
                  className="proposal-link-input"
                  value={publishedProposalLink()}
                  readOnly
                />
              </div>

              <Dropdown.Button
                className="publish-button copy-btn"
                onClick={() => copyPropsalLink()}
                overlay={publishButtonMenu}
                icon={<DropdownIcon />}>
                <NewCopyDarkIcon className="only-mobile" />
                <span className="link-text only-desktop">COPY LINK</span>
              </Dropdown.Button>
            </>
          ) : (
            !isTemplate &&
            !isSection && (
              <Button
                className="publish-button publish-button-right"
                loading={isPublishing}
                disabled={proposal.state === 'deleted'}
                onClick={() => publishProposal(true)}>
                <span className="link-text only-mobile">PUBLISH & GENERATE LINK</span>
                <span className="link-text only-desktop">PUBLISH & GENERATE SHARE LINK</span>
              </Button>
            )
          )}
        </React.Fragment>
      )}
    </Header>
  );
};

ProposalHeaderBar.defaultProps = {
  mode: 'wizard',
  isSaving: false,
  isPublishing: false,
  openPublishView: () => {},
  saveProposal: () => {},
  publishProposal: () => {},
  copyPropsalLink: () => {},
  publishedProposalLink: () => {},
  downloadPdf: () => {},
  updateScreen: () => {},
  screen: '',
  proposal: {},
  published: false,
  user: '',
  isTemplate: false,
  isSection: false,
  disablePreview: false,
  updateLoaderState: () => {},
  setProp: () => {},
  configText: () => {},
  setKeyboardModal: () => {},
};

ProposalHeaderBar.propTypes = {
  mode: PropTypes.oneOf(['wizard', 'draft', 'publish']),
  proposal: PropTypes.instanceOf(Object),
  saveProposal: PropTypes.func,
  isSaving: PropTypes.bool,
  isPublishing: PropTypes.bool,
  published: PropTypes.bool,
  isTemplate: PropTypes.bool,
  isSection: PropTypes.bool,
  openPublishView: PropTypes.func,
  publishProposal: PropTypes.func,
  copyPropsalLink: PropTypes.func,
  publishedProposalLink: PropTypes.func,
  downloadPdf: PropTypes.func,
  updateScreen: PropTypes.func,
  screen: PropTypes.string,
  user: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  undoableStack: PropTypes.instanceOf(Object),
  disablePreview: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  scrollToSection: PropTypes.func,
  updateLoaderState: PropTypes.func,
  propRef: PropTypes.instanceOf(Object),
  setProp: PropTypes.func,
  configText: PropTypes.func,
  setKeyboardModal: PropTypes.func,
  buttons: PropTypes.node,
};
export default ProposalHeaderBar;
