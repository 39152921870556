import { useEffect } from 'react';
import utils from '../../utils/utils';

const SetScrollbarWidth = () => {
  useEffect(() => {
    const scrollbarWidth = utils.getScrollbarWidth();
    // Set the CSS variable in :root
    document.documentElement.style.setProperty('--scroll-bar-width', `${scrollbarWidth}px`);
  }, []);

  return null;
};

export default SetScrollbarWidth;
