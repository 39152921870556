import utils from 'utils/utils';
import helpers from 'helpers/proposal';
import _ from 'lodash';
import useWindowDimensions from 'hooks/useWindowDimensions';

import './Tex.scss';

const TeXHTMLString = ({ content, configText, props, blockIndex }) => {
  const { isMobile } = useWindowDimensions();

  let json;

  try {
    json = JSON.parse(content);
  } catch (exx) {
    return '[pricing block]';
  }

  if (!json) {
    return '[pricing block]';
  }

  const decimalPrecision = props?.prop?.decimalPrecision || 2;

  if (json.superPowers) {
    const jsonobj = json.superPowers;
    let bigbody = [];

    const supers = configText('superpowers simple');
    const superToText = {};
    _.each(supers.split('\n'), (l) => {
      // superToText[l.split(':')[0]] = l.split(':')[1].trim();
    });

    const fakelist = _.map(jsonobj, (v, k) => ({
      name: k,
      super: {
        text: v && typeof v.text === 'string' ? v.text : superToText[k],
        order: v.order || 0,
        description: v.description || '',
      },
    })).sort((a, b) => a.super.order - b.super.order);

    let body = _.compact(
      _.map(fakelist, (x, superIndex) => {
        const supert = x.super;

        if (supert.text === '') {
          return;
        }

        const description = supert.description && (
          <p className="item-description" style={{ wordBreak: 'break-word' }}>
            {supert.description}
          </p>
        );

        if (supert.description)
          return (
            <li className="super-li" key={superIndex}>
              <div>{supert.text || ''}</div>
              {description}
            </li>
          );
        return (
          <li className="super-li" key={superIndex}>
            {supert.text || ''}
          </li>
        );
      })
    );

    bigbody.push(
      <div className="texblockbody">
        <ol>{body}</ol>
      </div>
    );

    return bigbody;
  }
  if (json.milestones) {
    const jsonobj = json.milestones;

    // if list doesnt exist then, return empty arr
    if (!jsonobj?.chosen || (jsonobj.chosen && Object.keys(jsonobj.chosen).length === 0)) {
      return [];
    }

    let bigbody = [];

    const [fkey] = Object.keys(jsonobj.chosen);
    jsonobj.chosen = { [fkey]: jsonobj.chosen[fkey] };

    _.map(jsonobj.chosen, (v, chosenKey) => {
      // max length of time colum text
      const timeLength = v.list.reduce((acc, x) => Math.max(acc, (x?.time || '').trim().length), 0);
      // if name or description length > 120 limit time column to 'medium'
      const isLargeName =
        timeLength > 0 && v.list.some((x) => x?.name?.length > 60 || x?.description?.length > 80);
      // dynamic size of time column based on content
      const timeColumnSize = getColumnSize(timeLength, isLargeName);

      let body = _.map(v.list, (x, milestonesIndex) => {
        const { time } = x || {};
        const trclass = 'timerow tablerow';
        const timetd = timeLength > 0 && (
          <td className={`time ${timeColumnSize}`}>
            <div className="time-text">{time || ''}</div>
          </td>
        );

        const description = x?.description && (
          <p className="item-description" style={{ wordBreak: 'break-word' }}>
            {x.description}
          </p>
        );

        if (x?.description)
          return (
            <tr className={trclass} key={milestonesIndex}>
              <td className="name">
                <div>{x?.name}</div>
                {description}
              </td>
              {timetd}
            </tr>
          );
        return (
          <tr className={trclass} key={milestonesIndex}>
            <td className="name">{x?.name}</td>
            {timetd}
          </tr>
        );
      });

      bigbody.push(
        <div key={chosenKey} className="texblockbody">
          <table>
            <tbody className="texblockbodyinner">{body}</tbody>
          </table>
        </div>
      );
    });

    return bigbody;
  }
  if (json.deliverables) {
    const jsonobj = json.deliverables;
    let bigbody = [];

    const curr = json.curr || props.prop.currency || (configText ? configText('currency') : '$');
    const [fkey] = Object.keys(jsonobj.chosen);
    let roundTotal = json.roundTotal ? json.roundTotal : false;

    json.pricing = json.pricing || { strategy: 'table' };
    jsonobj.chosen =
      json.pricing.strategy === 'value' ? jsonobj.chosen : { [fkey]: jsonobj.chosen[fkey] };

    // switch if tax is type -1 or the other way
    helpers.taxdisfix(jsonobj.chosen);

    let pkgnum = 0;
    const newchosen = {};

    let selectable = undefined;

    if (jsonobj?.chosen[fkey]) {
      selectable = jsonobj?.chosen[fkey].selectable;
    }

    if (json?.pricing?.strategy !== 'value' && jsonobj?.chosen[fkey]?.list) {
      const _temp = jsonobj.chosen[fkey];

      const items = _temp.list;
      roundTotal = _temp.roundTotal; //eslint-disable-line

      items.forEach((i) => {
        if (i.type) {
          newchosen[i.type] = newchosen[i.type] ? newchosen[i.type] : {};
          newchosen[i.type].name = i.type;
          newchosen[i.type].quantity = _temp.quantity;
          newchosen[i.type].tax = _temp.tax;
          newchosen[i.type].discount = _temp.discount;
          newchosen[i.type].subtotal = _temp.subtotal;
          newchosen[i.type].showTotal = _temp.showTotal;
          newchosen[i.type].totalText = _temp.totalText;
          newchosen[i.type].subtotalText = _temp.subtotalText;
          newchosen[i.type].subtotalBeforeTax = _temp?.subtotalBeforeTax || '';
          newchosen[i.type].subtotalBeforeTaxText = _temp?.subtotalBeforeTaxText || '';
          newchosen[i.type]['subtotalBeforeTaxTextOne-Time'] =
            _temp?.['subtotalBeforeTaxTextOne-Time'] || '';
          newchosen[i.type].subtotalBeforeTaxTextHourly = _temp?.subtotalBeforeTaxTextHourly || '';
          newchosen[i.type].subtotalBeforeTaxTextMonthly =
            _temp?.subtotalBeforeTaxTextMonthly || '';
          newchosen[i.type].subtotalBeforeTaxTextAnnually =
            _temp?.subtotalBeforeTaxTextAnnually || '';
          newchosen[i.type].selectable = _temp.selectable;
          newchosen[i.type].pricing = json.pricing;
          newchosen[i.type].structured = _temp?.structured || false;
          newchosen[i.type].columnName = _temp?.columnName || {};
          newchosen[i.type].list = newchosen[i.type]?.list?.length ? newchosen[i.type].list : [];
          newchosen[i.type].list.push(i);
        } else {
          newchosen[0] = newchosen[0] ? newchosen[0] : {};
          newchosen[0].name = 0;
          newchosen[0].quantity = _temp.quantity;
          newchosen[0].tax = _temp.tax;
          newchosen[0].discount = _temp.discount;
          newchosen[0].subtotal = _temp.subtotal;
          newchosen[0].showTotal = _temp.showTotal;
          newchosen[0].totalText = _temp.totalText;
          newchosen[0].subtotalText = _temp.subtotalText;
          newchosen[0].subtotalBeforeTax = _temp?.subtotalBeforeTax || '';
          newchosen[0].subtotalBeforeTaxText = _temp?.subtotalBeforeTaxText || '';
          newchosen[0]['subtotalBeforeTaxTextOne-Time'] =
            _temp?.['subtotalBeforeTaxTextOne-Time'] || '';
          newchosen[0].subtotalBeforeTaxTextHourly = _temp?.subtotalBeforeTaxTextHourly || '';
          newchosen[0].subtotalBeforeTaxTextMonthly = _temp?.subtotalBeforeTaxTextMonthly || '';
          newchosen[0].subtotalBeforeTaxTextAnnually = _temp?.subtotalBeforeTaxTextAnnually || '';
          newchosen[0].selectable = _temp.selectable;
          newchosen[0].pricing = json.pricing;
          newchosen[0].structured = _temp?.structured || false;
          newchosen[0].columnName = _temp?.columnName || {};
          newchosen[0].list = newchosen[0]?.list?.length ? newchosen[0].list : [];
          newchosen[0].list.push(i);
        }
      });
      jsonobj.chosen = newchosen;
    }

    const calcDiscPrice = (price, discount, symbol, isDotSeparator) => {
      if (isDotSeparator) {
        discount = Number(String(discount).replaceAll(',', '.'));
        price = Number(String(price).replaceAll(',', '.'));
      }
      if (discount && price) {
        if (symbol === '%') {
          if (discount >= 100) {
            if (discount > 100) return price;
          }
          price = price - price * (discount / 100);
        } else {
          price = price - discount;
        }
      }
      return price;
    };

    const calculateSubTotal = (prop, chosen) => {
      if (json?.pricing?.strategy) {
        // calculate sub total of all items in the list/manual input

        if (json.pricing.strategy === 'table') {
          // add all price, or add all price * items
          if (chosen?.list) {
            let totes = 0;
            chosen.list.forEach((item) => {
              const discVal = item?.discount?.value || 0;
              const discUnit = item?.discount?.unit || '%';

              const price =
                prop.priceSeperator === '.'
                  ? item?.currentPrice
                    ? String(item?.currentPrice)?.replaceAll(',', '.')
                    : String(item?.price)?.replaceAll(',', '.')
                  : item?.currentPrice
                  ? item?.currentPrice
                  : item?.price;

              const disc =
                prop.priceSeperator === '.' ? String(discVal)?.replaceAll(',', '.') : discVal;

              let subTotal = 0;

              if (chosen.selectable) {
                if (item.checked) {
                  subTotal = calcDiscPrice(
                    parseFloat(price * (chosen?.quantity ? item.items || 1 : 1)),
                    parseFloat(disc * (discUnit !== '%' && chosen?.quantity ? item.items || 1 : 1)),
                    discUnit,
                    prop.priceSeperator === '.'
                  );
                } else {
                  subTotal = 0;
                }
              } else {
                subTotal = calcDiscPrice(
                  parseFloat(price * (chosen?.quantity ? item.items || 1 : 1)),
                  parseFloat(disc * (discUnit !== '%' && chosen?.quantity ? item.items || 1 : 1)),
                  discUnit,
                  prop.priceSeperator === '.'
                );
              }
              totes += Number(subTotal) || 0;
            });
            return totes;
          }
          return 0;
        } else {
          // getting the subtotal value from input
          return chosen?.subtotalValue || 0;
        }
      }
      return chosen?.subtotalValue || 0;
    };

    // get header text
    const getHeaderText = (value, defaultValue) => {
      return typeof value === 'string' ? value : defaultValue;
    };

    _.map(jsonobj.chosen, (v, deliverablesIndex) => {
      pkgnum++;
      let calctotal = calculateSubTotal(props?.prop, v);
      const subTotal = JSON.parse(JSON.stringify(calctotal));

      let pkgname = v?.name || deliverablesIndex.toUpperCase();
      const pkgnameTranslated = configText ? configText(pkgname) || pkgname : pkgname;

      // whether table or value strategy
      const tableStrategy = json.pricing.strategy !== 'value';
      const chosenKey = tableStrategy ? pkgname : fkey;

      // if structured is checked
      const structured = !!v?.structured;

      // should show qty column ?
      // const qtyColumn = (!tableStrategy && v?.quantity && (v.type === 'Monthly' || v.type === 'Annually')) || (json.pricing.strategy !== 'value' && v?.quantity) ? true : false;
      const qtyColumn =
        (!tableStrategy && v?.quantity) || (json.pricing.strategy !== 'value' && v?.quantity)
          ? true
          : false;

      const getBody = props?.prop?.draft?.bodyFont?.fontSize || false;

      roundTotal = !tableStrategy ? v.roundTotal : roundTotal;
      selectable = v?.selectable;

      let body = _.map(v?.list, (x, deliverablesBodyIndex) => {
        const calcDiscountPrice = () => {
          if (x?.discount?.unit === '%') {
            // If the discount is in percentage, we'll calculate the discounted price.
            if (x?.discount?.value >= 0 && x?.discount?.value <= 100) {
              const discountAmount = (x?.discount?.value / 100) * x?.currentPrice;
              return x?.currentPrice - discountAmount || x.price || 0;
            }
          } else {
            // If the discount unit is not %, we'll directly subtract it from the item price.
            if (x?.discount?.value > 0) {
              return x?.discount?.value ? x?.currentPrice - x?.discount?.value : x?.price || 0;
            } else {
              return x.currentPrice || x.price || 0;
            }
          }
        };

        const discPrice = calcDiscountPrice();
        const currentPrice = helpers.concatcurr({
          prop: props?.prop || null,
          x: x.currentPrice || x.price || 0,
          curr,
          limitDec: roundTotal ? 0 : decimalPrecision,
          roundedTotal: roundTotal,
          parsingDecimal: roundTotal ? false : true,
        });

        const discWithCurr = helpers.concatcurr({
          prop: props?.prop || null,
          x: x?.discount?.value,
          curr,
          limitDec: roundTotal ? 0 : decimalPrecision,
          roundedTotal: roundTotal,
          parsingDecimal: roundTotal ? false : true,
        });
        const summedCurrentPrice = helpers.concatcurr({
          prop: props?.prop || null,
          x: x.currentPrice || 0,
          y: v?.quantity ? x.items : 1,
          curr,
          limitDec: roundTotal ? 0 : decimalPrecision,
          roundedTotal: roundTotal,
          parsingDecimal: roundTotal ? false : true,
        });
        const summedPrice = helpers.concatcurr({
          prop: props?.prop || null,
          x: discPrice || 0,
          y: v?.quantity ? x.items : 1,
          curr,
          limitDec: roundTotal ? 0 : decimalPrecision,
          roundedTotal: roundTotal,
          parsingDecimal: roundTotal ? false : true,
        });

        x.items = x.items || 1;
        // calctotal +=
        //   Number(
        //     ((x.checked && selectable) || !selectable
        //       ? v.quantity
        //         ? x.items
        //           ? x.items * (x.price || 0)
        //           : 0 * (x.price || 0)
        //         : x.price || 0
        //       : 0
        //     )
        //       ?.toString()
        //       ?.replace(/[^0-9.-]+/g, '')
        //   ) || 0;

        // var total = helpers.concatcurr(props?.prop || null, price, curr, 0, roundTotal);
        const trclass =
          deliverablesBodyIndex === v.list.length - 1
            ? 'tablerow finalrow'
            : !tableStrategy
            ? 'pricerow valuerow tr-340'
            : 'pricerow tablerow tr-341';

        const pricetdStyle = selectable && tableStrategy ? { flex: 0.3 } : {};
        const disctdStyle =
          x.discount && x.discount.value !== ''
            ? { visibility: 'visible', marginLeft: '0px' }
            : { visibility: 'hidden', display: 'none' };
        const discPriceStyle =
          x.discount && x.discount.value !== ''
            ? { visibility: 'visible' }
            : { visibility: 'hidden', display: 'none' };

        /*
        const discVal = helpers.concatcurr({
          prop: props?.prop || null,
          x:
            parseFloat(
              props?.prop.priceSeperator === '.'
                ? String(x?.discount?.value).replaceAll(',', '.')
                : String(x?.discount?.value)
            ) * (x?.discount?.unit !== '%' && v?.quantity ? x.items || 1 : 1),
          curr: x?.discount?.unit || curr,
          limitDec: decimalPrecision,
          roundedTotal: true,
        });
        */

        const discText = configText ? configText('discount text') : 'Discount';
        let pricetd = '';

        if (json.pricing.strategy === 'value') {
          pricetd = (
            <>
              {qtyColumn && (
                <td
                  className={`quantity td-373 ${json.pricing.strategy === 'value' && 'price'}`}>{`${
                  !structured ? 'x ' : ''
                }${x?.items || ''}`}</td>
              )}
            </>
          );
        } else {
          pricetd = (
            <>
              {qtyColumn && (
                <td className="quantity td-380" style={pricetdStyle}>
                  {`${!structured ? 'x ' : ''}${x?.items || ''}`}
                </td>
              )}
              <td className={`price td-383`} style={pricetdStyle}>
                {isMobile ? (
                  <>
                    <div className="price-amount">{summedPrice}</div>
                    <div className="price-discount-style">
                      <Amount amount={<del style={discPriceStyle}>{summedCurrentPrice}</del>} />
                    </div>
                  </>
                ) : (
                  <div className={`price-amount ${summedCurrentPrice.length > 10 ? 'long' : ''}`}>
                    <del className="no-break no-rtl" style={discPriceStyle}>
                      {summedCurrentPrice}
                    </del>
                    &nbsp;
                    <span className={`final-amount no-break no-rtl`}>{summedPrice}</span>
                  </div>
                )}
                {qtyColumn && x.items > 1 && (
                  <div className={`price-discount-style`}>
                    {structured ? (
                      <>
                        <span>
                          {getHeaderText(
                            jsonobj?.chosen[chosenKey].columnName?.rowprice,
                            configText('rowprice')
                          )}
                        </span>
                        &nbsp;
                        <span className={`discount-amount`}>
                          <Amount amount={currentPrice} />
                        </span>
                      </>
                    ) : (
                      <Amount amount={`${x?.items} × ${currentPrice}`} />
                    )}
                  </div>
                )}
                <div style={disctdStyle} className={`price-discount-style`}>
                  <span>{discText}:</span>
                  &nbsp;
                  <Amount
                    amount={
                      x?.discount?.unit === '%' ? `${x?.discount?.value || 0}%` : discWithCurr
                    }
                  />
                </div>
              </td>
            </>
          );
        }

        const forHourly =
          v.quantity &&
          x.quantity &&
          (v.name === 'Hourly' || v.type === 'Hourly') &&
          `(for ${x.quantity} ${x.quantity > 1 ? 'hours' : 'hours'})`;
        const forMonthly =
          v.quantity &&
          x.quantity &&
          (v.name === 'Monthly' || v.type === 'Monthly') &&
          `(for ${x.quantity} ${x.quantity > 1 ? 'months' : 'month'})`;
        const forAnnually =
          v.quantity &&
          x.years &&
          (v.name === 'Annually' || v.type === 'Annually') &&
          `(for ${x.years} ${x.years > 1 ? 'years' : 'year'})`;

        const description = x.description && <>{x.description}</>;

        const tdCheckboxDisabled = props.prop.state === 'approved' || selectable === 'locked';
        let tdCheckboxStyle =
          props?.prop?.language?.toLowerCase() === 'hebrew' ||
          props?.prop?.draft?.section?.language?.toLowerCase() === 'hebrew' ||
          props?.prop?.draft?.item?.language?.toLowerCase() === 'hebrew' ||
          props?.prop?.language?.toLowerCase() === 'arabic' ||
          props?.prop?.draft?.section?.language?.toLowerCase() === 'arabic' ||
          props?.prop?.draft?.item?.language?.toLowerCase() === 'arabic'
            ? { right: '0px' }
            : { left: '18px' };
        if (/Mobi|Android/i.test(navigator.userAgent)) {
          tdCheckboxStyle = { flex: '0.1', ...tdCheckboxStyle };
        } else {
          tdCheckboxStyle = { position: 'absolute', ...tdCheckboxStyle };
        }

        let itemDivStyle =
          props?.prop?.language?.toLowerCase() === 'hebrew' ||
          props?.prop?.draft?.section?.language?.toLowerCase() === 'hebrew' ||
          props?.prop?.draft?.item?.language?.toLowerCase() === 'hebrew' ||
          props?.prop?.language?.toLowerCase() === 'arabic' ||
          props?.prop?.draft?.section?.language?.toLowerCase() === 'arabic' ||
          props?.prop?.draft?.item?.language?.toLowerCase() === 'arabic'
            ? { right: '20px' }
            : { left: '28px' };

        itemDivStyle = {
          position: isMobile ? 'inherit' : 'relative',
          bottom: '3px',
          ...itemDivStyle,
        };

        const descStyles =
          props?.prop?.language?.toLowerCase() === 'hebrew' ||
          props?.prop?.draft?.section?.language?.toLowerCase() === 'hebrew' ||
          props?.prop?.draft?.item?.language?.toLowerCase() === 'hebrew' ||
          props?.prop?.language?.toLowerCase() === 'arabic' ||
          props?.prop?.draft?.section?.language?.toLowerCase() === 'arabic' ||
          props?.prop?.draft?.item?.language?.toLowerCase() === 'arabic'
            ? { right: '20px' }
            : { left: '28px' };

        const tdCheckbox = selectable && (
          <div
            className="pricing-layout-check-box"
            key={`${deliverablesBodyIndex}-td-checkbox`}
            style={{ ...tdCheckboxStyle, borderBottom: '0px' }}>
            <label className="nobreak-secondary-checkbox has-top-padding" id={props.name}>
              <input
                type="checkbox"
                defaultChecked={x.checked ? true : false}
                // for table always use index 0, for value use 0,1,2...
                value={tableStrategy ? 0 : pkgnum - 1}
                disabled={tdCheckboxDisabled ? true : false}
                id={`${x.id}-${blockIndex}-${deliverablesBodyIndex}-${props.name}`}
                className="priceTableCheckbox"
              />
              <span className="nobreak-secondary-checkbox-checkmark h2" />
            </label>
          </div>
        );

        return (
          <tr
            className={trclass}
            style={tdCheckbox ? { position: 'relative' } : {}}
            key={`${deliverablesBodyIndex}-tr`}>
            <td
              key={`${deliverablesBodyIndex}-td`}
              className="name td-457"
              style={tdCheckbox ? { flex: '0.7' } : {}}>
              {tdCheckbox}
              <div className="item-title" style={tdCheckbox ? itemDivStyle : {}}>
                {x.name} {forHourly || forMonthly || forAnnually}
              </div>
              <p className="item-description" style={tdCheckbox && !isMobile ? descStyles : {}}>
                {x.description && description}
              </p>
            </td>
            {/* {JSON.stringify({ pricetd })} */}
            {pricetd}
          </tr>
        );
      });

      // let testBody = [];
      // // const chunkSize = 1;
      // const html = ReactDOMServer.renderToStaticMarkup(body);
      // const cells = html.toString().match(/<td>*?<\/td>/g);
      // console.log(html.toString(), cells, '=-=-=>body');
      // const parser = new DOMParser();
      // const rowDocument = parser.parseFromString(body, 'text/html');
      // const cells = Array.from(rowDocument.querySelectorAll('td')).map(cell => cell.outerHTML);
      // console.log(rowDocument, cells, '=-=-=>rowDocument');
      // for (let i = 0; i < cells?.length; i += chunkSize) {
      //   const chunk = cells.slice(i, i + chunkSize);
      //   testBody.push(`<tr>${chunk.join('')}</tr>`);
      // }

      // const chunkSize = 100; // Adjust this value as needed
      // const rows = [];
      // for (let i = 0; i < html.toString().length; i += chunkSize) {
      //   rows.push(`<tr>${html.toString().slice(i, i + chunkSize)}</tr>`);
      // }

      // body = testBody;

      if (Object.keys(jsonobj.chosen).length === 1 && v?.name === 'One-Time') pkgname = '0';

      let paymentType = v?.type;
      paymentType = configText && paymentType ? `(${configText(paymentType) || paymentType})` : '';

      const opheadline = !tableStrategy ? (
        <div className={`pkg-name pkg-${pkgnum}`} style={{ fontSize: `1.25em` }}>
          <b>
            <u>
              {pkgnameTranslated}
              {paymentType}
            </u>
          </b>
        </div>
      ) : (
        pkgname !== '0' && (
          <div className={`pkg-name pkg-${pkgnum}`} style={{ fontSize: `1.25em` }}>
            <b>
              <u>{pkgnameTranslated}</u>
            </b>
          </div>
        )
      );

      // table heading
      let tableHeading = null;

      tableHeading = structured && (
        <tr className="pricerow tablerow tr-507">
          <td className="name">
            <div>
              {getHeaderText(
                jsonobj?.chosen[chosenKey].columnName?.deliverable,
                configText('Deliverable')
              )}
            </div>
          </td>
          {((tableStrategy && jsonobj?.chosen[chosenKey].quantity) ||
            (!tableStrategy && qtyColumn)) && (
            <td className={`quantity td-513 ${json.pricing.strategy === 'value' && 'price'}`}>
              <div>
                {isMobile
                  ? getHeaderText(jsonobj?.chosen[chosenKey].columnName?.item, configText('QTY'))
                  : getHeaderText(
                      jsonobj?.chosen[chosenKey].columnName?.item,
                      configText('quantity')
                    )}
              </div>
            </td>
          )}
          {json.pricing.strategy !== 'value' && (
            <td className="price td-519">
              <div>
                {getHeaderText(
                  jsonobj?.chosen[chosenKey].columnName?.price,
                  configText('line total text')
                )}
              </div>
            </td>
          )}
        </tr>
      );

      let subtotalhtml = '';
      let totalhtml = '';
      const subt = !!v?.subtotal;
      let subtval =
        tableStrategy && jsonobj?.chosen[fkey]?.subtotalValue === undefined ? calctotal : v.total;

      if (props && props.prop.priceSeperator === '.' && subtval)
        subtval = Number(subtval?.toString().split(',').join('.'));

      const fdisvalraw =
        v?.discount &&
        helpers.parseFloatCurr({
          prop: props?.prop || null,
          x: v.discount.val,
        });
      const fdisval =
        fdisvalraw &&
        Math.abs(fdisvalraw) * (v?.discount?.type ? v?.discount?.type : fdisvalraw < 0 ? -1 : 1);

      const ftaxvalraw =
        v?.tax && helpers.parseFloatCurr({ prop: props?.prop || null, x: v?.tax?.val });
      const ftaxval =
        ftaxvalraw &&
        Math.abs(ftaxvalraw) * (v?.tax?.type ? v?.tax?.type : ftaxvalraw < 0 ? -1 : 1);

      const subtotaltext = configText ? v?.subtotalText || configText('subtotal text') : 'Subtotal';
      const subtotalBeforeTaxText = configText
        ? v?.[`subtotalBeforeTaxText${tableStrategy ? v?.name || '' : ''}`] ||
          configText('total before tax')
        : 'Total before tax';

      const calcdis = () => {
        if (v?.discount && subt) {
          if (v?.discount.unit === '%') {
            subtval =
              json?.pricing?.strategy !== 'value' &&
              jsonobj?.chosen[fkey]?.subtotalValue === undefined
                ? calctotal
                : subtval / (1 - (-1 * fdisval) / 100);
          } else {
            subtval =
              json?.pricing?.strategy !== 'value' &&
              jsonobj?.chosen[fkey]?.subtotalValue === undefined
                ? calctotal
                : subtval - fdisval;
          }
        }
      };

      const calctax = () => {
        if (v?.tax && subt) {
          if (v?.tax?.unit === '%') {
            subtval =
              tableStrategy && jsonobj?.chosen[fkey]?.subtotalValue === undefined
                ? calctotal
                : subtval / (1 - (-1 * ftaxval) / 100);
          } else {
            subtval =
              tableStrategy && jsonobj?.chosen[fkey]?.subtotalValue === undefined
                ? calctotal
                : subtval - ftaxval;
          }
        }
      };

      if (tableStrategy) {
        calcdis();
        calctax();
      } else {
        calctax();
        calcdis();
      }

      if (!tableStrategy && v.subtotalValue) {
        subtval = v?.subtotalValue;
      }

      if (v?.subtotal) {
        subtval = helpers.concatcurr({
          prop: props?.prop || null,
          x:
            props?.prop?.priceSeperator === '.' && subtval
              ? subtval?.toString().replace('.', ',')
              : subtval,
          curr,
          limitDec: roundTotal ? 0 : decimalPrecision,
          roundedTotal: roundTotal,
          parsingDecimal: roundTotal ? false : true,
        });

        const cls = `sumrow subtrow${subt ? ' btoprow' : ''}`;

        subtotalhtml = (
          <tr className={cls}>
            <td className="name subt-text">{subtotaltext}</td>
            <td className="price total">
              <Amount amount={subtval} />
            </td>
          </tr>
        );
      }

      // discount
      let discounthtml = '';
      if (v?.discount) {
        const disval = helpers.concatcurr({
          prop: props?.prop || null,
          x: v?.discount.type ? Math.abs(fdisval) : fdisvalraw || 0,
          curr: v.discount.unit || curr,
          limitDec: decimalPrecision,
          roundedTotal: true,
        });
        if (v.discount?.unit === '%') {
          calctotal += (fdisval / 100) * calctotal;
        } else {
          calctotal += fdisval;
        }

        const discValue = () => {
          if (v?.discount?.unit === '%') {
            const minusedValue = (subTotal * fdisval) / 100;
            const calcDiscValue = helpers.concatcurr({
              prop: props?.prop || null,
              x: minusedValue || 0,
              curr: curr,
              limitDec: decimalPrecision,
              roundedTotal: roundTotal,
              parsingDecimal: roundTotal ? false : true,
            });
            return calcDiscValue;
          }
          return disval;
        };

        const disunit = v?.discount?.unit === '%' ? 'percent' : 'value';
        const cls = `sumrow discountrow${subt ? '' : ' btoprow'}`;
        discounthtml = (
          <tr className={cls}>
            <td className="name subt-text">
              {v?.discount?.text} {disunit === 'percent' && `(${disval.replaceAll(' ', '')})`}
            </td>
            <td className={`price total unit-${disunit}`}>
              <Amount amount={discValue()} />
            </td>
          </tr>
        );
      }

      // total before tax
      let totalBeforeTaxHtml = '';
      if (v?.subtotalBeforeTax) {
        // Evaluate the value before tax using 'calctotal' expression
        const absoluteCalctotal = utils.evaluateExpression(calctotal);
        const subtotalBeforeTaxValue = subtval
          ? helpers.concatcurr({
              prop: props?.prop || null,
              x:
                props?.prop?.priceSeperator === '.'
                  ? absoluteCalctotal.toString().replace('.', ',')
                  : absoluteCalctotal,
              curr,
              limitDec: roundTotal ? 0 : decimalPrecision,
              roundedTotal: roundTotal,
              parsingDecimal: roundTotal ? false : true,
            })
          : `${curr}0`;

        const cls = `sumrow total-before-tax subtrow${subt ? ' btoprow' : ''}`;
        totalBeforeTaxHtml = (
          <tr className={cls}>
            <td className="name subt-text total-before-tax">{subtotalBeforeTaxText}</td>
            <td className="price total total-before-value">
              <Amount amount={subtotalBeforeTaxValue} />
            </td>
          </tr>
        );
      }

      // tax
      let taxhtml = '';
      if (v?.tax) {
        const taxval = helpers.concatcurr({
          prop: props?.prop || null,
          x: v?.tax?.type ? Math.abs(ftaxval) : ftaxvalraw || 0,
          curr: v?.tax?.unit || curr,
          limitDec: decimalPrecision,
          roundedTotal: true,
        });
        if (v?.tax?.unit === '%') {
          calctotal += (ftaxval / 100) * calctotal;
        } else {
          calctotal += ftaxval;
        }
        const taxValue = () => {
          if (v?.tax?.unit === '%') {
            const totalBeforeTax = calcDiscPrice(
              subTotal,
              v?.discount?.val,
              v?.discount?.unit,
              props?.prop.priceSeperator === '.'
            );
            const addedValue = (totalBeforeTax * ftaxval) / 100;
            const calcTaxValue = helpers.concatcurr({
              prop: props?.prop || null,
              x: addedValue || 0,
              curr: curr,
              limitDec: decimalPrecision,
              roundedTotal: roundTotal,
              parsingDecimal: roundTotal ? false : true,
            });
            return calcTaxValue;
          }
          return taxval;
        };

        const taxunit = v?.tax?.unit === '%' ? 'percent' : 'value';
        const cls = `sumrow discountrow taxrow${subt ? '' : ' btoprow'}`;
        taxhtml = (
          <tr className={cls}>
            <td className="name subt-text">
              {v?.tax?.text} {taxunit === 'percent' && `(${taxval.replaceAll(' ', '')})`}
            </td>
            <td className={`price total unit-${taxunit}`}>
              <Amount amount={taxValue()} />
            </td>
          </tr>
        );
      }

      let total = '';
      if (json?.pricing?.strategy.indexOf('table') > -1) {
        total = helpers.concatcurr({
          prop: props?.prop || null,
          x:
            props.prop.priceSeperator === '.'
              ? calctotal?.toString().replace('.', ',') || 0
              : calctotal || 0,
          curr,
          minimum: 0,
          limitDec: roundTotal ? 0 : decimalPrecision,
          roundedTotal: roundTotal,
          parsingDecimal: roundTotal ? false : true,
        });
      } else {
        total = helpers.concatcurr({
          prop: props?.prop || null,
          x:
            props.prop.priceSeperator === '.'
              ? v?.total?.toString().replace('.', ',') || 0
              : v?.total || 0,
          curr,
          minimum: 0,
          limitDec: roundTotal ? 0 : decimalPrecision,
          roundedTotal: roundTotal,
          parsingDecimal: roundTotal ? false : true,
        });
      }

      const totaltext = configText ? v?.totalText || configText('total text') : 'Total';

      let showTotal = true;
      if (typeof v?.showTotal === 'undefined') {
        showTotal = true;
      } else {
        showTotal = !!v?.showTotal;
      }

      if (showTotal)
        totalhtml = (
          <tr className="sumrow totalrow">
            <td className="name total">{totaltext}</td>
            <td className="price total price-amount">
              <Amount amount={total} />
            </td>
          </tr>
        );

      let customInput = '';

      if (props?.prop?.selectedOption && json.pricing.strategy === 'value')
        customInput = (
          <label
            className="nobreak-secondary-checkbox"
            style={props.prop.selectedOption ? { display: 'block' } : { display: 'none' }}>
            <input
              type="checkbox"
              checked={
                pkgname.toLowerCase() === props.prop.selectedOption.toLowerCase() ? 'checked' : ''
              }
              disabled
            />
            <span className="nobreak-secondary-checkbox-checkmark h1" />
          </label>
        );

      let fontSizeValue = 0;
      if (getBody) [fontSizeValue] = getBody.match(/\d+/);

      if (fontSizeValue && fontSizeValue <= 14) {
        bigbody.push(
          <div
            className={`nobreak-secondary ${
              selectable && json.pricing.strategy !== 'value' ? 'checkbox-enabled' : ''
            }`}
            key={`${deliverablesIndex}-body`}>
            {customInput}
            <div className="texblockbody">
              {opheadline}
              <table
                className={
                  taxhtml || totalBeforeTaxHtml || discounthtml || subtotalhtml ? 'has-totals' : ''
                }>
                <thead className="pricing-table-heading">{tableHeading}</thead>
                <tbody className="texblockbodyinner">{body ? body : null}</tbody>
              </table>
              <table className="texblocktotals">
                <tbody>
                  {(!tableStrategy && subtotalhtml) ||
                  (tableStrategy && subtotalhtml && (discounthtml || taxhtml))
                    ? subtotalhtml
                    : null}
                  {discounthtml ? discounthtml : null}
                  {totalBeforeTaxHtml ? totalBeforeTaxHtml : null}
                  {taxhtml ? taxhtml : null}
                  {totalhtml ? totalhtml : null}
                </tbody>
              </table>
            </div>
          </div>
        );
      } else {
        bigbody.push(
          <div
            className={`nobreak-secondary ${
              selectable && json.pricing.strategy !== 'value' ? 'checkbox-enabled' : ''
            }`}
            key={`${deliverablesIndex}-body`}>
            {customInput}
            <div className="texblockbody">
              {opheadline}
              <table
                className={
                  taxhtml || totalBeforeTaxHtml || discounthtml || subtotalhtml ? 'has-totals' : ''
                }>
                <thead className="pricing-table-heading">{tableHeading}</thead>
                <tbody className="texblockbodyinner">{body ? body : null}</tbody>
              </table>
              <table className="texblocktotals">
                <tbody>
                  {(!tableStrategy && subtotalhtml) ||
                  (tableStrategy && subtotalhtml && (discounthtml || taxhtml))
                    ? subtotalhtml
                    : null}
                  {discounthtml ? discounthtml : null}
                  {totalBeforeTaxHtml ? totalBeforeTaxHtml : null}
                  {taxhtml ? taxhtml : null}
                  {totalhtml ? totalhtml : null}
                </tbody>
              </table>
            </div>
          </div>
        );
      }
    });

    return bigbody;
  }

  return null;
};

export default TeXHTMLString;

function Amount({ amount }) {
  return <div className="no-break no-rtl">{amount}</div>;
}

const getColumnSize = (timeLength, isLargeName) => {
  if (timeLength > 15) {
    if (!isLargeName) {
      if (timeLength > 21) {
        return 'size-7';
      }
      if (timeLength > 18) {
        return 'size-6';
      }
    }
    return 'size-5';
  }

  if (timeLength > 12) {
    if (!isLargeName && timeLength > 15) {
      return 'size-5';
    }
    return 'size-4';
  }

  if (timeLength > 9) {
    return 'size-3';
  }
  if (timeLength > 5) {
    return 'size-2';
  }
  return 'size-1';
};
